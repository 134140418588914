import React from 'react'

const Matrix = () => {
  return (
    <div>
    <h1>matrix</h1>
  </div>
  )
}

export default Matrix
