import React from 'react'

const ToastMaster = () => {
  return (
    <div>
    <h1>toastmaster</h1>
  </div>
  )
}

export default ToastMaster;
