import React from 'react'

const C2s2 = () => {
  return (
    <div>
       <div>
      <h1>c2s2</h1>
    </div>
    </div>
  )
}

export default C2s2
