import React from 'react'

const GFG = () => {
  return (
    <div>
    <h1>GFG</h1>
  </div>
  )
}

export default GFG
